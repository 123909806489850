var productName = 'grid';// Polyfills
import 'core-js/es';
import 'whatwg-fetch';
import 'abortcontroller-polyfill';

// Export library
export * from './webpack.entry.js';
// Export WebComponents
export * from './webpack.entry.wc.js';

// UMD inject anchor. Do not edit ot remove this last line.
