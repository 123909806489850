(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("grid", [], factory);
	else if(typeof exports === 'object')
		exports["grid"] = factory();
	else
		root["bryntum"] = root["bryntum"] || {}, root["bryntum"]["grid"] = factory();
})(window, function() {
return 